import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { HOSTEL } from "../../../utils/hostel.Api";
import { STUDENT_SESSION } from "../../../utils/student.Api";
// import FeePayment from "./FeePayment";

function HostelFees({ setLoading, user, uid }) {
  const [fee, setFee] = useState([]);

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${STUDENT_SESSION}?student_id=${uid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then(async (res) => {
        let data = [];
        try {
          await Promise.all(
            res.data.data.map(async (element) => {
              const config1 = {
                method: "get",
                url: `${HOSTEL}?student_id=${element.data.id}`,
                headers: {
                  "Content-Type": "application/json",
                },
              };

              const res = await axios(config1);
              let x = 0;
              for (var i = 0; i < res.data.data?.length; i++) {
                for (var j = 0; j < res.data.data[i].payment?.length; j++) {
                  x = x + parseInt(res.data.data[i].payment[j].payment_amount);
                }
              }
              data.push({
                amount: res.data.data[0].amount,
                session_id: res.data.data[0].session_id,
                paid_amount: x,
                fee_type_id: "Hostel Fees",
              });
            })
          );
        } catch (err) {
          console.log(err);
        }

        setFee(data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="StudentFee">
      {/* <FeePayment data={modaldata} user={user} /> */}

      <div className="row">
        <h4 className="col-12 my-3">Hostel Fees</h4>
        <div className="col-12">
          <table className="table table-bordered nowrap overflow-auto">
            <thead>
              <tr>
                <th>Sl No.</th>
                <th>Academic Year</th>
                <th>Amount</th>
                <th>Paid</th>
                <th>Balance</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {fee?.map((i, key) => (
                <tr>
                  <td>{key + 1}</td>
                  <td>{i?.session_id}</td>
                  <td>₹ {i?.amount}</td>
                  <td>₹ {i?.paid_amount}</td>
                  <td>₹ {i?.amount - i?.paid_amount}</td>
                  <td>
                    <span
                      className={`badge badge-soft-${
                        i?.amount - i?.paid_amount == 0
                          ? "success"
                          : i?.paid_amount == 0
                          ? "danger"
                          : "warning"
                      }`}
                    >
                      {i?.amount - i?.paid_amount == 0
                        ? "paid"
                        : i?.paid == 0
                        ? "not paid"
                        : "partial"}
                    </span>
                  </td>

                  {i?.amount - i?.paid_amount > 0 ? (
                    <td>
                      <button
                        data-toggle="modal"
                        style={{ cursor: "pointer" }}
                        data-target="#FeeModal"
                        onClick={() => {
                          let det =
                            fee &&
                            fee?.filter((s) => s.session_id == i?.session_id);
                          console.log(det);
                        }}
                        className="btn mb-2 mt-2 ml-3 btn-primary btn-small waves-effect waves-light"
                      >
                        {" "}
                        Pay
                      </button>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default HostelFees;
