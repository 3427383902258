import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  HDFC_PAYMENT_GATEWAY,
  PAYMENT_DETAILS,
} from "../../../utils/apiConstant";
import axios from "axios";

const FeePayment = (props) => {
  const [amount1, setAmount1] = useState();
  const [total, setTotal] = useState("");
  const [name, setName] = useState(props?.user?.name);
  const [email, setEmail] = useState(props?.user?.email);
  const [usn, setUsn] = useState(props?.user?.user_id);
  const [address, setAddress] = useState(props?.user?.address);
  const [city, setCity] = useState(props?.user?.city);
  const [flag, setFlag] = useState(false);
  const [stateName, setState] = useState("Gujarat");
  const [country, setCountry] = useState("India");
  const [pincode, setPincode] = useState("");
  const [faculty, setFaculty] = useState(props?.user?.college_id);
  const [session, setSession] = useState("");
  const ref = useRef();

  const [data, setData] = useState();
  const handleSubmit = async () => {
    if (parseInt(amount1) > total) {
      return toast.error("Entered Amount cannot be greater than due amount");
    }
    if (!usn) {
      return toast.error("Please enter enrollment number");
    }
    setFaculty(props?.user?.college_id);
    const arr = props?.allData?.filter(
      (s) =>
        s.fee_id ==
        props.feetypes?.filter((s) => s.value1 == session)[0]?.fee_id
    );
    let arr2 = [];
    let amt1 = amount1;
    for (const i of arr) {
      const obj = {
        amount: Number(i.amount),
        fee_type_id: i.fee_type_id,
        paid_amount: Number(i.paid_amount),
        discount_amount: Number(i.discount_amount),
        fee_id: i.fee_id,
        payment_details: [...i.payment_details],
        student_session_id: i?.student_session_id,
      };
      if (selectedValue?.filter((s) => s.label == i.fee_type_id)?.length > 0) {
        if (amt1 >= i.amount) {
          obj.paid_amount += Number(i.amount);
          amt1 -= i.amount;
        } else if (amt1 > 0) {
          obj.paid_amount += Number(amt1);
          amt1 = 0;
        }
        obj.mode = "STUDENT_PORTAL_FEE_PAYMENT";
        obj.note = "STUDENT_PORTAL_FEE_PAYMENT";
        obj.date = new Date().toISOString();
      }
      arr2.push(obj);
    }
    if (!amount1) {
      toast.error("Please Select Relevant Amount");
      return;
    }
    let currentSession = props?.allData?.filter((s) => s.session_id == session);
    const config1 = {
      headers: {
        "Content-Type": "application/json",
      },
      url: PAYMENT_DETAILS,
      data: {
        fee_details_id: currentSession[0]?.fee_details_id,
        payment_details: arr2,
        student_session_id: currentSession[0]?.student_session_id,
      },
      method: "post",
    };

    await axios(config1)
      .then(async (res) => {
        const obj = {
          type: "STUDENT_PORTAL_FEE_PAYMENT",
          payment_for: "ACADEMIC FEES",
          data: {
            student: {
              student_session_id: props.allData?.filter(
                (s) => s.session_id == session
              )[0]?.student_session_id,
              college_id: props.allData?.filter(
                (s) => s.session_id == session
              )[0]?.college_id,
            },
            feeData: {},
            feeDetails: res?.data?.data?.id,
            collected_by: "Student",
          },
        };
        let str1 = JSON.stringify(obj);
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
          url: HDFC_PAYMENT_GATEWAY,
          data: {
            amount: Number(amount1),
            merchant_param1: props?.user?.name,
            merchant_param2: usn || props?.user?.user_id,
            merchant_param3: email || props?.user?.email,
            merchant_param4: "UMS_ERP",
            merchant_param5: str1,
            billing_address: address,
            billing_city: city,
            billing_state: stateName,
            billing_country: country,
            billing_zip: pincode,
            transaction_date: new Date().toISOString().split("T")[0],
            department_id: props?.user?.department_id,
            program_id: props?.user?.program_id,
            merchant_faculty: props?.user?.college_id,
          },
          method: "post",
        };

        await axios(config)
          .then((res) => {
            setData(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (data && data.encRequest && data.accessCode) {
      ref.current.click();
    }
  }, [data]);

  useEffect(() => {
    setName(props?.user?.name);
    setEmail(props?.user?.email);
    setUsn(props?.user?.user_id);
    setAddress(props?.user?.address);
    setCity(props?.user?.city);
    setFaculty(props?.user?.college_id);
  }, []);

  const selectAllOption = { label: "Select All", value: "all" };

  const [selectedFees, setSelectedFees] = useState([]);

  const handleSelectChange = (selectedValues) => {
    if (selectedValues.includes(selectAllOption)) {
      let x = props.feetypes?.filter((s) => s.value1 == session);
      setSelectedFees(x);
    } else {
      setSelectedFees(selectedValues.filter((val) => val !== selectAllOption));
    }
  };

  const isSelectAllSelected = selectedFees?.length === props.feetypes?.length;

  var selectedValue = isSelectAllSelected ? [...props.feetypes] : selectedFees;

  useEffect(() => {
    let amt = 0;
    selectedValue?.forEach((element) => {
      amt +=
        props?.allData?.filter(
          (s) => s.session_id == session && s.fee_type_id == element.label
        )[0]?.amount -
        props?.allData?.filter(
          (s) => s.session_id == session && s.fee_type_id == element.label
        )[0]?.paid_amount;
    });
    setTotal(amt);
  }, [selectedValue]);

  useEffect(() => {}, []);

  return (
    <div
      className="modal fade text-left"
      id="FeeModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered mw-100 w-75"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Fee Details
            </h5>
            <button
              className="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row mt-2">
              <div className="col-6">
                <div className="form-group">
                  <label>Select Session</label>
                  <select
                    className="form-control"
                    name="session"
                    onChange={(e) => {
                      setTotal("");
                      setFlag((flag) => !flag);
                      handleSelectChange([]);
                      setSession(e.target.value);
                    }}
                    value={session}
                  >
                    <option>Select Session</option>
                    {props.sessions &&
                      props.sessions?.map((item, key) => {
                        return <option value={item}>{item}</option>;
                      })}
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Select Type Of Fees</label>
                  <Select
                    isMulti
                    options={[
                      selectAllOption,
                      ...props.feetypes?.filter((s) => s.value1 == session),
                    ]}
                    value={selectedValue}
                    onChange={handleSelectChange}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <div className="form-group">
                  <label>Total Payable Fee</label>
                  <input
                    value={total}
                    readOnly={true}
                    type="number"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Enter Amount Fee</label>
                  <input
                    value={amount1}
                    onChange={(e) => setAmount1(e.target.value)}
                    type="number"
                    className="form-control"
                    placeholder="Enter Amount"
                  />
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-6">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    value={name || props?.user?.name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Enter Full Name here"
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Enrollment Number(optional)</label>
                  <input
                    value={usn || props?.user?.user_id}
                    onChange={(e) => setUsn(e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder="Starts with SUK..."
                  />
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    value={email || props?.user?.email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    className="form-control"
                    name="email"
                    placeholder="Enter Email here"
                  />
                  <p>
                    <strong className="text-danger"> Note: </strong> You will
                    recieve a email with the reciept of payment
                  </p>
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <label>Address</label>
                  <input
                    type="text"
                    value={address}
                    name="address"
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Enter Address here"
                  />
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-6">
                <div className="form-group">
                  <label>City </label>
                  <input
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    type="text"
                    name="city"
                    className="form-control"
                    placeholder="Enter City here"
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>state</label>
                  <input
                    value={stateName}
                    onChange={(e) => setState(e.target.value)}
                    type="text"
                    name="state"
                    className="form-control"
                    placeholder="Enter state here"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-6">
                <div className="form-group">
                  <label>Country</label>
                  <input
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    type="text"
                    name="country"
                    className="form-control"
                    placeholder="Enter Country here"
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>Pincode</label>
                  <input
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    type="text"
                    className="form-control"
                    name="pincode"
                    placeholder="Enter Pincode here"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex">
              <button
                onClick={() => handleSubmit()}
                className="btn btn-danger mt-3 mb-5"
              >
                Pay Now
              </button>

              {data && data.encRequest && data.accessCode && (
                <form
                  method="post"
                  action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
                >
                  <input
                    type="hidden"
                    id="encRequest"
                    name="encRequest"
                    value={data.encRequest}
                  />
                  <input
                    type="hidden"
                    name="access_code"
                    id="access_code"
                    value={data.accessCode}
                  />
                  <input
                    ref={ref}
                    type="submit"
                    style={{ display: "none" }}
                    className="btn btn-danger"
                    value="Pay Now"
                  />
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeePayment;
