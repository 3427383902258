import React from "react";
import { useState, useEffect } from "react";
import OtherDocs from "../../modals/DocumentModal";
import { STUDENT } from "../../../utils/student.Api";
import axios from "axios";
import DocumentsModal from "../../modals/ViewDocumentModal";

function Documents({ setLoading, uid, data }) {
  const [details, setDetails] = useState([]);

  const [link, setLink] = useState("");

  const [flag, setFlag] = useState(false);

  const [title, setTitle] = useState("");

  function isStringified(str) {
    try {
      return JSON.parse(str);
    } catch (err) {
      return str;
    }
  }

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${STUDENT}/${uid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then(async (res) => {
        setLoading(0);
        try {
          if (res.data.data.aadhar) {
            res.data.data.aadhar = isStringified(res.data.data?.aadhar);
          }
          if (res.data.data.pu_markscard) {
            res.data.data.pu_markscard = isStringified(
              res.data.data?.pu_markscard
            );
          }
          if (res.data.data.sslc_markscard) {
            res.data.data.sslc_markscard = isStringified(
              res.data.data?.sslc_markscard
            );
          }
          if (res.data.data.other_docs) {
            res.data.data.other_docs = isStringified(res.data.data?.other_docs);
            console.log(res.data.data.other_docs);
          }
          console.log(res.data.data);
        } catch (error) {
          console.log(error);
        }
        setDetails(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="Documents">
      <OtherDocs
        data={details}
        id={uid}
        setLoading={setLoading}
        getData={getData}
      />
      <DocumentsModal img={link} title={title} setLink={setLink} />
      <div className="row">
        <div className="col-8">
          <h4>Documents Attached</h4>
        </div>
        <div className="col-4">
          <button
            className="btn float-right btn-primary btn-sm mt-2"
            type="submit"
            data-toggle="modal"
            data-target="#OtherDocs"
            name="submit"
          >
            Upload Other Documents
          </button>
        </div>
        <div className="col-12">
          <table className="table table-bordered nowrap overflow-auto mt-2">
            <thead>
              <tr>
                <th>Sl No.</th>
                <th>Document</th>
                <th>Status</th>
                <th style={{ maxWidth: "100px" }}>Attachment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>10th Marks Sheet</td>
                <td>
                  {details?.sslc_markscard?.status ? (
                    <span
                      className={`${
                        details?.sslc_markscard?.status == "PENDING"
                          ? "badge badge-soft-warning"
                          : details?.sslc_markscard?.status == "REJECTED"
                          ? "badge badge-soft-danger"
                          : details?.sslc_markscard?.status == "APPROVED" ||
                            details?.sslc_markscard?.status == "APPROVED1"
                          ? "badge badge-soft-success"
                          : null
                      }`}
                    >
                      {details?.sslc_markscard?.status == "APPROVED1"
                        ? "APPROVED BY ADMIN"
                        : details?.sslc_markscard?.status == "REJECTED"
                        ? "REJECTED BY REGISTRAR"
                        : details?.sslc_markscard?.status == "REJECTED1"
                        ? "REJECTED BY ADMIN"
                        : details?.sslc_markscard?.status == "APPROVED"
                        ? "APPROVED BY REGISTRAR"
                        : details?.sslc_markscard?.status}
                    </span>
                  ) : details?.sslc_markscard?.link.length > 0 ? (
                    <span className="badge badge-soft-warning">PENDING</span>
                  ) : (
                    <span className="badge badge-soft-danger">
                      NOT UPLOADED
                    </span>
                  )}
                </td>
                <td>
                  {details?.sslc_markscard?.link?.length > 0 ? (
                    <button
                      onClick={() => {
                        setLink(details?.sslc_markscard?.link);
                        setTitle("SSLC Marks Card");
                        setFlag((flag) => !flag);
                      }}
                      data-toggle="modal"
                      data-target="#DocumentsModal"
                      className="btn btn-primary btn-sm"
                    >
                      View
                    </button>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>12th Marks Sheet</td>
                <td>
                  {details?.pu_markscard?.status ? (
                    <span
                      className={`${
                        details?.pu_markscard?.status == "PENDING"
                          ? "badge badge-soft-warning"
                          : details?.pu_markscard?.status == "REJECTED"
                          ? "badge badge-soft-danger"
                          : details?.pu_markscard?.status == "APPROVED" ||
                            details?.pu_markscard?.status == "APPROVED1"
                          ? "badge badge-soft-success"
                          : null
                      }`}
                    >
                      {details?.pu_markscard?.status?.status == "APPROVED1"
                        ? "APPROVED BY ADMIN"
                        : details?.pu_markscard?.status?.status == "REJECTED"
                        ? "REJECTED BY REGISTRAR"
                        : details?.pu_markscard?.status?.status == "REJECTED1"
                        ? "REJECTED BY ADMIN"
                        : details?.pu_markscard?.status?.status == "APPROVED"
                        ? "APPROVED BY REGISTRAR"
                        : details?.pu_markscard?.status}
                    </span>
                  ) : details?.pu_markscard?.link ? (
                    "PENDING"
                  ) : (
                    <span className="badge badge-soft-danger">
                      NOT UPLOADED
                    </span>
                  )}
                </td>
                <td>
                  {details?.pu_markscard?.link?.length > 0 ? (
                    <button
                      onClick={() => {
                        setLink(details?.pu_markscard?.link);
                        setTitle("PU Marks Card");
                        setFlag((flag) => !flag);
                      }}
                      data-toggle="modal"
                      data-target="#DocumentsModal"
                      className="btn btn-primary btn-sm"
                    >
                      View
                    </button>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Student Photo</td>
                <td>
                  {details?.student_picture?.length > 0 ? (
                    <span className="badge badge-soft-success">UPLOADED</span>
                  ) : (
                    <span className="badge badge-soft-danger">
                      NOT UPLOADED
                    </span>
                  )}
                </td>
                <td>
                  {details?.student_picture?.length > 0 ? (
                    <button
                      onClick={() => {
                        setLink(details?.student_picture);
                        setTitle("Student Picture");
                        setFlag((flag) => !flag);
                      }}
                      data-toggle="modal"
                      data-target="#DocumentsModal"
                      className="btn btn-primary btn-sm"
                    >
                      View
                    </button>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Aadhar Card</td>
                <td>
                  {details?.aadhar?.status ? (
                    <span
                      className={`${
                        details?.aadhar?.status == "PENDING"
                          ? "badge badge-soft-warning"
                          : details?.aadhar?.status == "REJECTED"
                          ? "badge badge-soft-danger"
                          : details?.aadhar?.status == "APPROVED" ||
                            details?.aadhar?.status == "APPROVED1"
                          ? "badge badge-soft-success"
                          : null
                      }`}
                    >
                      {details?.aadhar?.status?.status == "APPROVED1"
                        ? "APPROVED BY ADMIN"
                        : details?.aadhar?.status?.status == "REJECTED"
                        ? "REJECTED BY REGISTRAR"
                        : details?.aadhar?.status?.status == "REJECTED1"
                        ? "REJECTED BY ADMIN"
                        : details?.aadhar?.status?.status == "APPROVED"
                        ? "APPROVED BY REGISTRAR"
                        : details?.aadhar?.status}
                    </span>
                  ) : details?.aadhar?.link?.length > 0 ? (
                    "PENDING"
                  ) : (
                    <span className="badge badge-soft-danger">
                      NOT UPLOADED
                    </span>
                  )}
                </td>
                <td>
                  {details?.aadhar?.link?.length > 0 ? (
                    <button
                      onClick={() => {
                        setLink(details?.aadhar?.link);
                        setFlag((flag) => !flag);
                        setTitle("Aadhar Card");
                      }}
                      data-toggle="modal"
                      data-target="#DocumentsModal"
                      className="btn btn-primary btn-sm"
                    >
                      View
                    </button>
                  ) : null}
                </td>
              </tr>
              {details &&
                details?.other_docs?.map((item, key) => (
                  <tr>
                    <td>{4 + key + 1}</td>
                    <td>{item?.title}</td>
                    <td>
                      {
                        <span
                          className={`${
                            item?.status == "PENDING"
                              ? "badge badge-soft-warning"
                              : item?.status == "REJECTED"
                              ? "badge badge-soft-danger"
                              : item?.status == "APPROVED" ||
                                item?.status == "APPROVED1"
                              ? "badge badge-soft-success"
                              : null
                          }`}
                        >
                          {item?.status}
                        </span>
                      }
                    </td>
                    <td>
                      {item?.link?.length > 0 ? (
                        <button
                          onClick={() => {
                            setLink(item?.link);
                            setTitle(item?.title);
                          }}
                          data-toggle="modal"
                          data-target="#DocumentsModal"
                          className="btn btn-primary btn-sm"
                        >
                          View
                        </button>
                      ) : null}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Documents;
