import React, { useEffect, useState } from 'react'
import { SEMESTER_ID, UID } from '../../utils/storageConstant'
import { Http } from '../../Services/Services'
import { STUDENT_ATTENDANCE } from '../../utils/student.Api'
import { Devlog } from '../../Helpers/Logger'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../Router/RouteConfig'

const Attendance = () => {

    const semester_id = sessionStorage.getItem(SEMESTER_ID)
    const student_id = sessionStorage.getItem(UID)

    const navigate = useNavigate()
    const [data, setData] = useState([])

    const getData = async () => {
        await Http.get(`${STUDENT_ATTENDANCE}?semester_id=${semester_id}&student_id=${student_id}`)
            .then(res => {
                Devlog("Attendance", res.data.data)
                setData(res.data.data)
            }
            )
            .catch(err => {
                Devlog("Attendance", err)
                toast.error("Something went wrong")
            }
            )
    }


    useEffect(() => {
        getData()
    }, [])
    return (
        <div className='main-content'>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="card-header">
                                        My Attendace
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <tr>
                                                <td>SL.No</td>
                                                <td>Subject</td>
                                                <td>Present</td>
                                                <td>Absent</td>
                                                <td>Percentage</td>
                                            </tr>
                                            {
                                                data.map((item, index) => {

                                                    const total = Number(item.present) + Number(item.absent);
                                                    const percentage = Number((item.present / total) * 100);

                                                    return <tr>
                                                        <td>{index + 1}</td>
                                                        <td onClick={() => navigate(ROUTES.Attendance + "/" + item.course_id)} className='text-primary cursor-pointer'>{item.course}</td>
                                                        <td>{item.present}</td>
                                                        <td>{item.absent}</td>
                                                        <td>
                                                            {
                                                                !item.present && !item.absent ?
                                                                    (
                                                                        <span className='badge badge-soft-secondary'>NA</span>
                                                                    )

                                                                    :
                                                                    percentage < 85 && percentage >= 60 ? (
                                                                        <span className='badge badge-soft-warning'>{percentage?.toFixed(2)}%</span>
                                                                    )
                                                                        :
                                                                        percentage < 60 ? (
                                                                            <span className='badge badge-soft-danger'>{percentage?.toFixed(2)}%</span>
                                                                        )
                                                                            :
                                                                            percentage >= 85 ? (
                                                                                <span className='badge badge-soft-success'>{percentage?.toFixed(2)}%</span>
                                                                            )
                                                                                : null


                                                            }
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Attendance