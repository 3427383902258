import React from "react";
import { useState, useRef } from "react";
import { ASSET_STUDENT_DOCUMENT } from "../../utils/AssetReferenceTypes";
import { getFileUrl } from "../../Helpers/Helpers";
import { toast } from "react-toastify";
import { STUDENT_ADMISSION } from "../../utils/apiConstant";
import axios from "axios";

function OtherDocs({ getData, setLoading, data, id }) {
  const fileref = useRef(null);

  const modalRef = useRef(null);

  const [flag, setFlag] = useState(false);

  const [title, setTitle] = useState("");

  const [type, setType] = useState("");

  function isStringified(str) {
    try {
      return JSON.parse(str);
    } catch (err) {
      return str;
    }
  }

  const handleSubmit = async () => {
    console.log(data);
    // return;
    setLoading(1);
    const config = {
      method: "put",
      url: `${STUDENT_ADMISSION}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Successfully Updated Details");
        modalRef.current && modalRef.current.click();
        setLoading(0);
        getData();
        fileref.current.value = null;
        setTitle("");
        setType("");
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
        toast.error("Something went wrong");
      });
  };

  const handleChange1 = async (e) => {
    setLoading(1);
    try {
      const d = await getFileUrl(
        ASSET_STUDENT_DOCUMENT,
        "STUDENT_OTHER_DOCS",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );

      if (type != "other_docs") {
        data[type] = isStringified(data[type]);
        let obj = {
          link: d,
          status: "PENDING",
          reason: "",
        };
        data[type] = obj;
      } else {
        data[type] = isStringified(data[type]);
        let obj = {
          link: d,
          title: title,
          status: "PENDING",
          reason: "",
        };
        let arr = [];
        if (data[type]?.length > 0) {
          arr = [...data[type]];
        }
        obj.id = arr.length + 1;
        arr.push(obj);
        data[type] = arr;
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="OtherDocs">
      <div
        className="modal fade"
        id="OtherDocs"
        ref={modalRef}
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered mw-100 w-75"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="text-primary">Upload Other Documents</h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-3">
                  <label>Select Type Of Document</label>
                  <select
                    className="form-control"
                    value={type}
                    name="type"
                    onChange={(e) => {
                      setType(e.target.value);
                      setFlag((flag) => !flag);
                    }}
                  >
                    <option value="">Select Type</option>
                    <option value="sslc_markscard">SSLC Marks Card</option>
                    <option value="pu_markscard">12th Marks Card</option>
                    <option value="aadhar">Aadhar Card</option>
                    <option value="other_docs">Other Documents</option>
                  </select>
                </div>
                {type == "other_docs" ? (
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">
                        Title <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter title"
                        className="form-control"
                        name="title"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {type?.length > 0 ? (
                  <>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="">
                          Link <span className="text-danger">*</span>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name="date"
                          ref={fileref}
                          onChange={(e) => {
                            handleChange1(e);
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <button
                        className="btn btn-success mt-4"
                        id="submit"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}

export default OtherDocs;
