// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar .cursor-pointer {
  cursor: pointer; }

.Navbar .navbarActiveClass {
  color: var(--navBarActiveColor) !important; }

.navCustomDropdown .navbarActiveClass {
  color: var(--navBarActiveColor); }
`, "",{"version":3,"sources":["webpack://./src/App/Components/Navbar/Navbar.scss"],"names":[],"mappings":"AAAA;EAGQ,eAAe,EAAA;;AAHvB;EAMQ,0CAA0C,EAAA;;AAKlD;EAEQ,+BAA+B,EAAA","sourcesContent":[".Navbar{\n    // Top Nav two properties are in app.css\n    .cursor-pointer{\n        cursor: pointer;\n    }\n    .navbarActiveClass{\n        color: var(--navBarActiveColor) !important;\n    }\n}\n\n\n.navCustomDropdown{\n    .navbarActiveClass{\n        color: var(--navBarActiveColor);\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
