import React, { useEffect, useState } from 'react'
import { SEMESTER_ID, UID } from '../../utils/storageConstant'
import { Http } from '../../Services/Services'
import { STUDENT_ATTENDANCE, STUDENT_ATTENDANCE_BY_COURSE } from '../../utils/student.Api'
import { Devlog } from '../../Helpers/Logger'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'

const AttendanceCourse = () => {

    const { course_id } = useParams()
    const student_id = sessionStorage.getItem(UID)
    const [data, setData] = useState([])

    const getData = async () => {
        await Http.get(`${STUDENT_ATTENDANCE_BY_COURSE}?course_id=${course_id}&student_id=${student_id}`)
            .then(res => {
                Devlog("Attendance", res.data.data)
                setData(res.data.data)
            }
            )
            .catch(err => {
                Devlog("Attendance", err)
                toast.error("Something went wrong")
            }
            )
    }


    useEffect(() => {
        getData()
    }, [])
    return (
        <div className='main-content'>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="card-header">
                                        My Attendace
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="card-header">
                                        Student Attendance - {data?.student_id}
                                    </h6>

                                    <div className="table-responsive">
                                        <table className='table table-bordered'>
                                            <tr>
                                                <th>date</th>
                                                <th>time</th>
                                                <th>status</th>
                                            </tr>
                                            {
                                                data?.attendance?.map((i, key) => (
                                                    <tr>
                                                        <td>{i?.date}</td>
                                                        <td>{i?.time_from} - {i?.time_to}</td>
                                                        <td>
                                                            <span className={`badge badge-soft-${i?.attendance_status == "PRESENT" ? "success" : "danger"}`}>
                                                                {i?.attendance_status}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AttendanceCourse