// export const BASE_URL = `http://localhost:4000/api`;

export const BASE_URL = "https://server.swaminarayanuniversity.ac.in/api";

export const LOGIN = `${BASE_URL}/student/login`;

export const RESET_PASSWORD = `${BASE_URL}/student/reset-password`;

export const STUDENT_ADMISSION = `${BASE_URL}/student`;

export const FORGOT_PASSWORD = `${BASE_URL}/student/forgot-password`;

export const ALL_DATA = `${BASE_URL}/hr/data/getall`;
export const AWS_URL_GENERATOR = `${BASE_URL}/asset/url`; //post Request

export const REDIRECT = `${BASE_URL}/student/portal/redirect-login`;

//HDFC Payment Gateway
export const HDFC_PAYMENT_GATEWAY = `${BASE_URL}/payment/hdfc`;

export const PAYMENT_DETAILS = `${BASE_URL}/payment/createPaymentDetails`;

export const GET_PAYMENT_DETAILS = `${BASE_URL}/payment/getPaymentDetails`;
