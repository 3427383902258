// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Topbar #page-topbar {
  position: static; }

.Topbar .cursor-pointer {
  cursor: pointer !important; }

.Topbar .dropdown-menu {
  z-index: 9999; }

.Topbar .action-list {
  position: absolute;
  right: 0;
  top: 68px;
  padding: 10px;
  border-radius: 10px;
  background: white;
  z-index: 9999;
  box-shadow: 4px 4px 10px 2px; }
  .Topbar .action-list ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0; }
    .Topbar .action-list ul li {
      display: flex; }

@media screen and (max-width: 988px) {
  .Topbar .w-sm-100 {
    width: 100%; } }

.Topbar .collegeOptSelect {
  text-align: right; }
  .Topbar .collegeOptSelect option {
    text-align: left; }
`, "",{"version":3,"sources":["webpack://./src/App/Components/Topbar/Topbar.scss"],"names":[],"mappings":"AAAA;EAEQ,gBAAgB,EAAA;;AAFxB;EAMQ,0BAA0B,EAAA;;AANlC;EAUQ,aAAa,EAAA;;AAVrB;EAcQ,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;EACb,4BAA4B,EAAA;EArBpC;IAuBY,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,UAAU,EAAA;IA3BtB;MA8BgB,aAAa,EAAA;;AAKzB;EAnCJ;IAqCY,WAAW,EAAA,EACd;;AAtCT;EA0CQ,iBAAiB,EAAA;EA1CzB;IA6CY,gBAAgB,EAAA","sourcesContent":[".Topbar {\n    #page-topbar {\n        position: static;\n    }\n\n    .cursor-pointer {\n        cursor: pointer !important;\n    }\n\n    .dropdown-menu {\n        z-index: 9999;\n    }\n\n    .action-list {\n        position: absolute;\n        right: 0;\n        top: 68px;\n        padding: 10px;\n        border-radius: 10px;\n        background: white;\n        z-index: 9999;\n        box-shadow: 4px 4px 10px 2px;\n        ul{\n            list-style: none;\n            display: flex;\n            flex-direction: column;\n            margin: 0;\n            padding: 0;\n\n            li{\n                display: flex;\n            }\n        }\n    }\n\n    @media screen and (max-width:988px) {\n        .w-sm-100 {\n            width: 100%;\n        }\n    }\n\n    .collegeOptSelect {\n        text-align: right;\n\n        option {\n            text-align: left;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
