import React from "react";
import "./Topbar.scss";
import { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";

function Topbar() {
  const navigate = useNavigate();
  let name = sessionStorage.getItem("name");

  const Logout = () => {
    sessionStorage.clear();
    navigate("/");
  };

  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className="Topbar">
        <header id="page-topbar">
          <div className=" d-flex justify-content-between">
            <div className="d-flex justify-content-start mh-100">
              <div className="d-flex justify-content-start">
                <Link
                  to="#"
                  className="d-flex justify-content-center align-items-center px-2"
                  style={{ background: "white" }}
                >
                  <img
                    src={"/assets/images/logoWide.png"}
                    alt=""
                    className=""
                    width={180}
                  />
                </Link>
              </div>
            </div>

            <div className="d-flex">
              <p
                style={{
                  margin: "auto",
                  fontSize: "1.3rem",
                  marginRight: "1rem",
                  color: "white",
                }}
              >
                {name}
              </p>
              <button
                style={{ background: "none", border: "none" }}
                onClick={() => setOpen(!open)}
              >
                <img
                  className="rounded-circle header-profile-user"
                  src="/assets/images/users/avatar-2.jpg"
                  alt="Header Avatar"
                />
              </button>

              {open ? (
                <div className="action-list">
                  <ul>
                    <li>
                      <div
                        className="dropdown-item text-danger w-100 cursor-pointer"
                        onClick={() => {
                          Logout();
                        }}
                      >
                        <i className="ri-shut-down-line align-middle mr-1 text-danger" />{" "}
                        Logout
                      </div>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </header>
      </div>
    </div>
  );
}

export default Topbar;
