import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Http } from "../../Services/Services";
import { REDIRECT } from "../../utils/apiConstant";
import { toast } from "react-toastify";
import {
  COLLEGE,
  DEPARTMENT,
  PROGRAM,
  UID,
  USER_AUTH,
} from "../../utils/storageConstant";
import { ROUTES } from "../../Router/RouteConfig";

const Redirct = () => {
  const { token } = useParams();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("param");

  useEffect(() => {
    Http.post(REDIRECT + "/" + token, {})
      .then((res) => {
        toast.success(`User LoggedIn successfully`);
        console.log(res);
        navigate(ROUTES.Profile + "?app=1");
        sessionStorage.setItem(USER_AUTH, res.data.data.token);
        localStorage.setItem(USER_AUTH, res.data.data.token);
        localStorage.setItem("noty", true);
        sessionStorage.setItem(COLLEGE, res.data.data.college);
        sessionStorage.setItem(PROGRAM, res.data.data.program);
        sessionStorage.setItem(DEPARTMENT, res.data.data.department);
        sessionStorage.setItem(UID, res.data.data.id);
      })
      .catch((err) => {
        toast.error("Error");
      });
  }, []);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-header">Redirecting.........</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Redirct;
