import { ROUTES } from "../../Router/RouteConfig";

const drop = "dropdown";
const stat = "static";
const sheet = "sheet";
export const navbarDataStudent = [
  // {
  //     title:'Dashboard',
  //     type:stat,
  //     route:ROUTES.Home,
  //     icon:<i className="ri-folder-chart-line mr-2" />
  // },
  // {
  //     title:'Fees',
  //     type:drop,
  //     icon:<i className="ri-store-2-line mr-2" />,
  //     drop:[
  //         {
  //         title:'Payments',
  //         type:stat,
  //         route:ROUTES.Home
  //         },
  //         {
  //         title:'Download Fees Reciept',
  //         type:stat,
  //         route:ROUTES.Home
  //         },
  //     ]
  // },
  {
    title: "My Profile",
    type: stat,
    route: ROUTES.Profile,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Subjects",
    type: stat,
    route: ROUTES.Subjects,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Attendance",
    type: stat,
    route: ROUTES.Attendance,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
];
