import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { TRANSPORT } from "../../../utils/transport.Api";

function TransportFees({ setLoading, class1, tid }) {
  const [fee, setFee] = useState([]);
  const [sum, setSum] = useState();
  const [paid, setPaid] = useState();

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${TRANSPORT}?id=${tid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        setFee(res.data.data);
        setPaid(res.data.data[0]?.amount);
        let x = 0;
        for (var i = 0; i < res.data.data.length; i++) {
          for (var j = 0; j < res.data.data[i].payment.length; j++) {
            x = x + parseInt(res.data.data[i].payment[j].payment_amount);
          }
        }
        console.log(x);
        setSum(x);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="StudentFee">
      <div className="row">
        <h4 className="col-12 my-3">Transport Fees</h4>
        <div className="col-12">
          <table className="table table-bordered nowrap overflow-auto">
            <thead>
              <tr>
                <th>Sl No.</th>
                <th>Academic Year</th>
                <th>Class</th>
                <th>Amount</th>
                <th>Balance</th>
                <th>Paid</th>
                <th>Status</th>
                {parseInt(paid) - sum > 0 ? <th>Action</th> : null}
              </tr>
            </thead>
            <tbody>
              {fee?.map((i, key) => (
                <tr>
                  <td>{key + 1}</td>
                  <td>{i?.session_id}</td>
                  <td>{class1}</td>
                  <td>₹ {i?.amount}</td>
                  <td>₹ {i?.amount - parseInt(sum)}</td>
                  <td>₹ {parseInt(sum)}</td>
                  <td>
                    <span
                      className={`badge badge-soft-${
                        i?.amount - sum == 0
                          ? "success"
                          : sum == 0
                          ? "danger"
                          : "warning"
                      }`}
                    >
                      {i?.amount - sum == 0
                        ? "paid"
                        : sum == 0
                        ? "not paid"
                        : "partial"}
                    </span>
                  </td>

                  {i?.amount - parseInt(sum) > 0 ? (
                    <td>
                      <button className="btn mb-2 mt-2 ml-3 btn-primary btn-small waves-effect waves-light">
                        {" "}
                        Pay
                      </button>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TransportFees;
