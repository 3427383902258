// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileSwitches .nav-item .active {
  color: var(--basicBlue); }
`, "",{"version":3,"sources":["webpack://./src/App/Components/Profile/PersonalDetails/Profile.scss"],"names":[],"mappings":"AAAA;EAGY,uBAAuB,EAAA","sourcesContent":[".ProfileSwitches{\n    .nav-item{\n        .active{\n            color: var(--basicBlue);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
