import React from "react";
import "./Profile.scss";
import { useState, useEffect } from "react";

const ProfileSwitches = ({
  tab,
  setTab,
  hostel,
  transport,
  setFlag,
  save,
  saveFlag,
}) => {
  const [x, setx] = useState(false);
  const data = [
    "Basic",
    "College Details",
    "Fee",
    "Documents",
    "Hostel Fees",
    "Transport Fees",
  ];

  return (
    <div className="ProfileSwitches">
      <ul className="nav nav-tabs d-flex">
        {data.map((i, key) => {
          return i !== "Hostel Fees" && i !== "Transport Fees" ? (
            <>
              <li className="nav-item cursor-pointer">
                <a
                  className={`nav-link ${tab === i && "active"}`}
                  onClick={() => {
                    setTab(i);
                  }}
                  role="tab"
                >
                  {i}
                </a>
              </li>
            </>
          ) : i === "Hostel Fees" && hostel == 1 ? (
            <>
              <li className="nav-item cursor-pointer">
                <a
                  className={`nav-link ${tab === i && "active"}`}
                  onClick={() => {
                    setTab(i);
                  }}
                  role="tab"
                >
                  {i}
                </a>
              </li>
            </>
          ) : i === "Transport Fees" && transport == 1 ? (
            <>
              <li className="nav-item cursor-pointer">
                <a
                  className={`nav-link ${tab === i && "active"}`}
                  onClick={() => {
                    setTab(i);
                  }}
                  role="tab"
                >
                  {i}
                </a>
              </li>
            </>
          ) : null;
        })}
        {tab === "Basic" && (
          <>
            {!(x && tab == "Basic") ? (
              <button
                onClick={() => {
                  setFlag(true);
                  setx(true);
                  saveFlag(false);
                }}
                style={{ marginLeft: "auto" }}
                className="btn mb-2 btn-primary btn-small waves-effect waves-light"
              >
                Edit
              </button>
            ) : (
              <>
                <button
                  style={{ marginLeft: "auto" }}
                  className="btn mb-2 btn-primary btn-small waves-effect waves-light"
                  onClick={() => {
                    saveFlag(true);
                    setx(false);
                    setFlag(false);
                  }}
                >
                  Save
                </button>
                <button
                  style={{ marginLeft: "1rem" }}
                  className="btn mb-2 btn-primary btn-small waves-effect waves-light"
                  onClick={() => {
                    setFlag(false);
                    setx(false);
                  }}
                >
                  Cancel
                </button>
              </>
            )}
          </>
        )}
      </ul>
    </div>
  );
};

export default ProfileSwitches;
