import React, { useEffect, useState } from 'react'
import { SEMESTER_ID } from '../../utils/storageConstant'
import axios from 'axios'
import { Http } from '../../Services/Services'
import { TOPICS } from '../../utils/student.Api'
import { toast } from 'react-toastify'
import { Devlog } from '../../Helpers/Logger'
import { useParams } from 'react-router-dom'

const Topics = () => {

    const [data, setData] = useState([])
    const {lesson_id} = useParams()

    const getData = () => {
        Http.get(`${TOPICS}?lesson_id=${lesson_id}`)
        .then(res=>{
            Devlog("Topics", res.data.data)
            setData(res.data.data)
        })
        .catch(err=>{
            Devlog("Topics", err)
            toast.error("Something went wrong")
        }
        )
    }



    useEffect(()=>{
       getData()
    },[])
    
  return (
    <div className='main-content'>
        <div className="page-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="card-header">
                                    My Topics
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                            <div className="table-responsive">
                            <table className="table table-bordered">
                                <tr>
                                    <td>Sl No.</td>
                                    <td>Subject</td>
                                </tr>
                                {
                                    data.map((item, index)=>(
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{item.name}</td>
                                        </tr>
                                    ))

                                }
                            </table>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Topics