import { BASE_URL } from "./apiConstant.js"

export const STUDENT = `${BASE_URL}/student`;
export const STUDENT_SESSION = `${BASE_URL}/student/session/all`;
// export const FEE_DETAILS_BY_STUDENT_ID = `${BASE_URL}/fee/student-fee`;
export const FEE_DETAILS_BY_STUDENT_ID = `${BASE_URL}/student/portal/fee`


export const SUBJECTS = `${BASE_URL}/student/portal/subjects`;
export const LESSONS = `${BASE_URL}/student/portal/lessons`;
export const TOPICS = `${BASE_URL}/student/portal/topics`;
export const SYLLABUS_STATUS = `${BASE_URL}/student/portal-web/syllabus-status`;

export const STUDENT_ATTENDANCE = `${BASE_URL}/student/portal/attendance`;
export const STUDENT_ATTENDANCE_BY_COURSE = `${BASE_URL}/student/portal/attendance-by-course-id`;