import React, { useEffect, useState } from 'react'
import { SEMESTER_ID } from '../../utils/storageConstant'
import axios from 'axios'
import { Http } from '../../Services/Services'
import { SUBJECTS } from '../../utils/student.Api'
import { toast } from 'react-toastify'
import { Devlog } from '../../Helpers/Logger'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../Router/RouteConfig'

const Subjects = () => {

    const [data, setData] = useState([])

    const navigate = useNavigate()
    const semester_id = sessionStorage.getItem(SEMESTER_ID)


    const getData = async () => {
        await Http.get(`${SUBJECTS}?semester_id=${semester_id}`)
        .then(res=>{
            Devlog("Subjects", res.data.data)
            setData(res.data.data)
        })
        .catch(err=>{
            Devlog("Subjects", err)
            toast.error("Something went wrong")
        }
        )
    }


    useEffect(()=>{
        getData()
    },[semester_id])
    
  return (
    <div className='main-content'>
        <div className="page-content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="card-header">
                                    My Subjects
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                            <div className="table-responsive">
                            <table className="table table-bordered">
                                <tr>
                                    <td>Sl No.</td>
                                    <td>Subject</td>
                                    <td>Action</td>
                                </tr>
                                {
                                    data.map((item, index)=>(
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                <button onClick={()=>navigate(ROUTES.Lessons+'/'+item.id)} className="btn btn-sm btn-primary">
                                                    View Lessons
                                                </button>
                                            </td>
                                        </tr>
                                    ))

                                }
                            </table>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Subjects