// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
  --navBarActiveColor:#ff5e00;
  --basicBlue:#364277;
  --basicOrange:#FBAC7E;
}

.App{
  font-family: 'Poppins', sans-serif !important;
}

.page-content{
  padding: calc(10px + 24px) calc(24px / 2) 60px calc(24px / 2);
}

/* Navbar's Topbar design properties */
.topnav{
  margin-top: 0px !important;
  position: static;
}

.cursor-disable{
  cursor: not-allowed !important;
}

.cursor-pointer{
  cursor: pointer !important;
}


.cursor-normal{
  cursor:default !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 ,p ,div{
  font-family: 'Poppins',sans-serif !important;
}


.navbar-brand-box{
  width: 0px !important;
}

/* Custom Scrollbar CSS */

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--basicBlue);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(129, 127, 127)277;
}


.fc-daygrid-event{
  color: white !important;
}

.fc-daygrid-event:hover{
  color: black !important;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,2BAA2B;EAC3B,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,6DAA6D;AAC/D;;AAEA,sCAAsC;AACtC;EACE,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,0BAA0B;AAC5B;;;AAGA;EACE,yBAAyB;AAC3B;;AAEA;EACE,4CAA4C;AAC9C;;;AAGA;EACE,qBAAqB;AACvB;;AAEA,yBAAyB;;AAEzB,UAAU;AACV;EACE,UAAU;EACV,WAAW;AACb;;AAEA,UAAU;AACV;EACE,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,4BAA4B;AAC9B;;AAEA,oBAAoB;AACpB;EACE,iCAAiC;AACnC;;;AAGA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');\n\n:root{\n  --navBarActiveColor:#ff5e00;\n  --basicBlue:#364277;\n  --basicOrange:#FBAC7E;\n}\n\n.App{\n  font-family: 'Poppins', sans-serif !important;\n}\n\n.page-content{\n  padding: calc(10px + 24px) calc(24px / 2) 60px calc(24px / 2);\n}\n\n/* Navbar's Topbar design properties */\n.topnav{\n  margin-top: 0px !important;\n  position: static;\n}\n\n.cursor-disable{\n  cursor: not-allowed !important;\n}\n\n.cursor-pointer{\n  cursor: pointer !important;\n}\n\n\n.cursor-normal{\n  cursor:default !important;\n}\n\n.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 ,p ,div{\n  font-family: 'Poppins',sans-serif !important;\n}\n\n\n.navbar-brand-box{\n  width: 0px !important;\n}\n\n/* Custom Scrollbar CSS */\n\n/* width */\n::-webkit-scrollbar {\n  width: 7px;\n  height: 7px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: #f1f1f1;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: var(--basicBlue);\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: rgb(129, 127, 127)277;\n}\n\n\n.fc-daygrid-event{\n  color: white !important;\n}\n\n.fc-daygrid-event:hover{\n  color: black !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
