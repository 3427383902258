// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  height: 100vh;
  top: 0;
  width: 100vw;
  position: fixed;
  background: white;
  margin: auto;
  opacity: 0.5;
  z-index: 999;
  display: flex;
  z-index: 99999;
  align-items: center; }
  .loader span {
    display: block;
    margin: 0 auto;
    border-color: var(--basicBlue);
    position: fixed;
    margin: auto; }
`, "",{"version":3,"sources":["webpack://./src/App/Components/Loader/Loader.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,MAAM;EACN,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,cAAc;EACd,mBAAmB,EAAA;EAXvB;IAcM,cAAc;IACd,cAAc;IACd,8BAA8B;IAE9B,eAAe;IACf,YAAY,EAAA","sourcesContent":[".loader {\n    height: 100vh;\n    top: 0;\n    width: 100vw;\n    position: fixed;\n    background: white;\n    margin: auto;\n    opacity: 0.5;\n    z-index: 999;\n    display: flex;\n    z-index: 99999;\n    align-items: center;\n  \n    span{\n      display: block;\n      margin: 0 auto;\n      border-color: var(--basicBlue);\n    //   left: calc(50% - 75px);\n      position: fixed;\n      margin: auto;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
