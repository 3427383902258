import React, { useState } from 'react'
import { css } from "@emotion/react";
import HashLoader from "react-spinners/HashLoader";
import "./Loader.scss"

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--basicBlue);
  left: calc(50% - 75px);
  position: fixed;
`;

const Loader = ({loading}) => {


    let [color, setColor] = useState("#364277");

    return (
        <div>
            {loading ? <div className='loader'>
                <HashLoader color={color} loading={loading} css={override} size={50} />
            </div> : null}

        </div>
    )
}

export default Loader