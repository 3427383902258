import "./App.css";
import Router from "./App/Router/Router";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="App">
        <ToastContainer autoClose={2000} hideProgressBar={true} />
        <Router />
    </div>
  );
}

export default App;
