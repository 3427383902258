import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Loader from "../Components/Loader/Loader";
import { navbarDataStudent } from "../Data/Navbar/Student";
import Topbar from "../Components/Topbar/Topbar";
import axios from "axios";
import { useEffect } from "react";
import Home from "../Pages/Home/Home";
import Profile from "../Pages/Profile/Profile";
import Navbar from "./../Components/Navbar/Navbar";
import ForgotPassword from "../Pages/Login/ForgotPassword";
import {
  LOCAL_COLLEGE,
  LOCAL_COLLEGE_SPECIALIZATION,
  LOCAL_COLLEGE_TYPES,
  LOCAL_DEPARTMENT,
  LOCAL_EMPLOYEE,
  LOCAL_JOBROLES,
  LOCAL_PROGRAM,
  LOCAL_SPECIALIZATION,
  LOCAL_USER_ROLES,
} from "../utils/localStorageConstants";
import { ALL_DATA } from "../utils/apiConstant";
import Login from "../Pages/Login/Login";
import Redirct from "../Pages/Redirect/Redirct";
import Subjects from "../Pages/Academics/Subjects";
import { ROUTES } from "./RouteConfig";
import Topics from "../Pages/Academics/Topics";
import Lessons from "../Pages/Academics/Lessons";
import Attendance from "../Pages/Academics/Attendance";
import AttendanceCourse from "../Pages/Academics/AttendanceCourse";

const Router = () => {
  // const navigate = useNavigate();
  const [colleges, setColleges] = useState();

  const getAllData = async () => {
    var config = {
      method: "get",
      url: ALL_DATA,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        localStorage.setItem("ALL_DATA", JSON.stringify(res.data));
        localStorage.setItem(
          LOCAL_USER_ROLES,
          JSON.stringify(res.data.userRoles)
        );
        localStorage.setItem(LOCAL_COLLEGE, JSON.stringify(res.data.college));
        localStorage.setItem(
          LOCAL_COLLEGE_SPECIALIZATION,
          JSON.stringify(res.data.collegeSpecialization)
        );
        localStorage.setItem(
          LOCAL_COLLEGE_TYPES,
          JSON.stringify(res.data.collegeType)
        );
        localStorage.setItem(
          LOCAL_DEPARTMENT,
          JSON.stringify(res.data.department)
        );
        localStorage.setItem(LOCAL_JOBROLES, JSON.stringify(res.data.jobRoles));
        localStorage.setItem(LOCAL_PROGRAM, JSON.stringify(res.data.program));
        localStorage.setItem(
          LOCAL_SPECIALIZATION,
          JSON.stringify(res.data.specialization)
        );
        localStorage.setItem(
          LOCAL_USER_ROLES,
          JSON.stringify(res.data.userRoles)
        );
        localStorage.setItem(LOCAL_EMPLOYEE, JSON.stringify(res.data.employee));
        setColleges(res.data.college);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  const RouteWithStudent = ({ Element }) => {
    // let auth = sessionStorage.getItem("student_auth");

    // {auth ? null : <Navigate replace to="/" />}
    const [loading, setLoading] = useState(false);

    return (
      <>
        <Loader loading={loading} />
        <Topbar />
        <Navbar data={navbarDataStudent} />
        <Element setLoading={setLoading} />
        {/* <Footer /> */}
      </>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/redirect/:token" element={<Redirct />} />
        <Route
          exact
          path="/Home"
          element={<RouteWithStudent Element={Home} />}
        />
        //Change Password
        <Route exact path="/forgot-password/:id" element={<ForgotPassword />} />
        <Route
          exact
          path="/profile"
          element={<RouteWithStudent Element={Profile} />}
        />
        <Route exact path={ROUTES.Subjects} element={<RouteWithStudent Element={Subjects} />} />
        <Route exact path={ROUTES.Lessons+"/:course_id"} element={<RouteWithStudent Element={Lessons} />} />
        <Route exact path={ROUTES.Topics+"/:lesson_id"} element={<RouteWithStudent Element={Topics} />} />
        <Route exact path={ROUTES.Attendance} element={<RouteWithStudent Element={Attendance} />} />
        <Route exact path={ROUTES.Attendance+"/:course_id"} element={<RouteWithStudent Element={AttendanceCourse} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
