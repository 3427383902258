import axios from "axios";

const headers = {
    Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
    "Content-Type": "application/json",
}

export const Http = {
    get: async (url, cancelToken) => {
        const config = {
            method: 'get',
            headers: headers,
            url: url,
            cancelToken: cancelToken
        }
        const response = await axios(config)
        return response;
    },
    post: async (url, data) => {
        const config = {
            method: 'post',
            headers: headers,
            url: url,
            data: data
        }
        const response = await axios(config)
        return response;
    },
    put: async (url, data) => {
        const config = {
            method: 'put',
            headers: headers,
            url: url,
            data: data
        }
        const response = await axios(config)
        return response;
    }
}