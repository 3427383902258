import React, { useEffect, useState } from 'react'
import { SEMESTER_ID } from '../../utils/storageConstant'
import axios from 'axios'
import { Http } from '../../Services/Services'
import { LESSONS, SYLLABUS_STATUS, TOPICS } from '../../utils/student.Api'
import { toast } from 'react-toastify'
import { Devlog } from '../../Helpers/Logger'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../Router/RouteConfig'

const Lessons = () => {

    const [data, setData] = useState([])
    const { course_id } = useParams()

    const navigate = useNavigate()

    const getData = () => {
        Http.get(`${SYLLABUS_STATUS}/${course_id}`)
            .then(res => {
                Devlog("Lessons", res.data.data)
                setData(res.data.data)
            })
            .catch(err => {
                Devlog("Lessons", err)
                toast.error("Something went wrong")
            }
            )
    }



    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='main-content'>
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="card-header">
                                        My Subjects
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <tr>
                                                <td>Sl No.</td>
                                                <td>Lesson</td>
                                                <td>Topics</td>
                                                <td style={{minWidth:'150px'}}>Status</td>
                                            </tr>
                                            {/* {
                                    data.map((item, index)=>(
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{item.name}</td>
                                            <td>
                                                <button onClick={()=>navigate(ROUTES.Topics+'/'+item.id)} className="btn btn-sm btn-primary">
                                                    View Topics
                                                </button>
                                            </td>
                                        </tr>
                                    ))

                                } */}

                                            {
                                                data.map((item, index) => (
                                                    <>
                                                        <tr>
                                                            <td rowSpan={item?.topics?.length+1}>
                                                                {index + 1}
                                                            </td>
                                                            <td rowSpan={item?.topics?.length+1}>
                                                                {item?.lesson_name}
                                                            </td>

                                                            <td className='d-none'></td>
                                                            <td className='d-none'></td>
                                                        </tr>
                                                        {
                                                            item?.topics?.map((j, key) => (
                                                                <tr>
                                                                    <td>{j.topic_name}</td>
                                                                    <td>
                                                                        <div className='d-flex justify-content-center'>
                                                                            <span className={`badge badge-soft-${j.status?"success":'danger'}`}>{j.status?"Completed":"InComplete"}</span>
                                                                        </div>
                                                                        {
                                                                            j?.status
                                                                            ?
                                                                            <div className="text-center mt-1">
                                                                                {
                                                                                    j?.completion_date?.split("T")[0]
                                                                                }
                                                                            </div>
                                                                            :
                                                                            null

                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </>
                                                ))
                                            }

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Lessons