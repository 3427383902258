import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import { LOGIN, FORGOT_PASSWORD } from "./../../utils/apiConstant";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  COLLEGE,
  DEPARTMENT,
  PROGRAM,
  USER_AUTH,
  UID,
  CLASS_ID,
  SEMESTER_ID,
  SECTION_ID,
} from "../../utils/storageConstant";
import { ROUTES } from "../../Router/RouteConfig";

function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(0);

  const [selected, setSelected] = useState("login");

  const navigate = useNavigate();

  // LOGIN
  const login = async (e) => {
    e.preventDefault();

    if (!userName) {
      toast.error("Email is required");
      return;
    } else if (!password) {
      toast.error("Password is required");
      return;
    }

    const loginObj = {
      id: userName,
      password,
    };

    var config = {
      method: "post",
      url: LOGIN,
      headers: {
        "Content-Type": "application/json",
      },
      data: loginObj,
    };

    setLoading(1);

    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success(`User LoggedIn successfully`);
        console.log(res);
        navigate(ROUTES.Profile);
        sessionStorage.setItem(USER_AUTH, res.data.data.token);
        localStorage.setItem(USER_AUTH, res.data.data.token);
        localStorage.setItem("noty", true);
        sessionStorage.setItem(COLLEGE, res.data.data.college);
        sessionStorage.setItem(PROGRAM, res.data.data.program);
        sessionStorage.setItem(DEPARTMENT, res.data.data.department);
        sessionStorage.setItem(UID, res.data.data.id);
        sessionStorage.setItem(CLASS_ID, res.data.data.class_id);
        sessionStorage.setItem(SEMESTER_ID, res.data.data.semester_id);
        sessionStorage.setItem(SECTION_ID, res.data.data.section_id);
      })
      .catch((err) => {
        setLoading(0);
        localStorage.setItem("Student_Login_Id", err.response.data?.id);
        toast.error(err.response.data?.message);
        console.log(err);
      });
  };

  const handeResetPassword = async (e) => {
    e.preventDefault();
    setLoading(1);
    console.log(userName);
    if (!userName) return toast.error("Email is Required");
    const config = {
      method: "post",
      url: FORGOT_PASSWORD,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: userName,
      },
    };

    await axios(config)
      .then((res) => {
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
    setLoading(0);
  };

  return (
    <div className="Login" style={{ maxWidth: "100vw", overflow: "hidden" }}>
      <Loader loading={loading} />
      <>
        <div>
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-lg-8 m-auto">
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <div className="row justify-content-center">
                      <div className="col-lg-9">
                        <div>
                          <div className="text-center">
                            <div>
                              <a href="index.php" className="logo mb-5">
                                <img
                                  src="/assets/images/Nexenstial Logo.png"
                                  height={100}
                                  alt="logo"
                                />
                              </a>
                            </div>
                            <h4 className="font-size-18 mt-4">Welcome !</h4>
                            <p className="text-muted">
                              Sign in to continue to Swaminarayan University
                              Student Portal.
                            </p>
                          </div>
                          <div className="p-2 mt-5">
                            {selected == "login" ? (
                              <form className="form-horizontal">
                                <div className="form-group auth-form-group-custom mb-4">
                                  <i className="ri-user-2-line auti-custom-input-icon" />
                                  <label htmlFor="username">User Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="username"
                                    name="Email"
                                    placeholder="Enter User Name"
                                    value={userName}
                                    onChange={(e) => {
                                      setUserName(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="form-group auth-form-group-custom mb-4">
                                  <i className="ri-lock-2-line auti-custom-input-icon" />
                                  <label htmlFor="userpassword">Password</label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="userpassword"
                                    name="userpassword"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={(e) => {
                                      setPassword(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="mt-4 text-center">
                                  <button
                                    className="btn btn-primary btn-block w-md waves-effect waves-light"
                                    type="submit"
                                    name="submit"
                                    value="login"
                                    onClick={login}
                                  >
                                    Log In
                                  </button>
                                </div>
                                <div className="mt-4 text-center">
                                  <a
                                    onClick={() => setSelected("forgot")}
                                    className="cursor-pointer text-muted"
                                  >
                                    <i className="mdi mdi-lock mr-1" /> Forgot
                                    your password?
                                  </a>
                                </div>
                              </form>
                            ) : (
                              <form className="form-horizontal">
                                <div className="form-group auth-form-group-custom mb-4">
                                  <i className="ri-lock-2-line auti-custom-input-icon" />
                                  <label htmlFor="userpassword">Email</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Email"
                                    value={userName}
                                    name="userName"
                                    onChange={(e) => {
                                      setUserName(e.target.value);
                                    }}
                                  />
                                </div>

                                <div className="mt-4 text-center">
                                  <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="submit"
                                    name="submit"
                                    value="login"
                                    onClick={handeResetPassword}
                                  >
                                    Send Reset Link
                                  </button>
                                </div>

                                <div className="mt-5 text-center">
                                  <a
                                    onClick={() => {
                                      setSelected("login");
                                    }}
                                    className="cursor-pointer text-muted"
                                  >
                                    Go to Login
                                  </a>
                                </div>
                              </form>
                            )}
                          </div>
                          <div className="mt-5 text-center">
                            <p>
                              © {new Date().getFullYear()} College Name. Crafted
                              with <i className="mdi mdi-heart text-danger" />{" "}
                              by{" "}
                              <a href="https://www.nexenstial.com/">
                                {" "}
                                Nexenstial{" "}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Login;
